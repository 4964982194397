import React, { useContext, useEffect, useState } from "react";
import config from "../config.json";
import { LanguageContext } from "../context/languageContext";
import { useTranslation } from "react-i18next";

const Footer = ({ style, contentLoaded }) => {
  const langCtx = useContext(LanguageContext);
  const { t } = useTranslation();

  const [isContentLong, setIsContentLong] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsContentLong(document.body.clientHeight > window.innerHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [contentLoaded]);

  const ccLink = (
    <a href="https://www.camelcoding.com/" target="_blank" className="ccLink">
      CamelCoding
    </a>
  );

  const galaxisLink = (
    <a
      href="https://galaxis.xyz/"
      target="_blank"
      className="ccLink"
      style={{ paddingRight: "2px" }}
    >
      {` `}Galaxis,
    </a>
  );
  const cloudScaleLink = (
    <a
      href="https://www.facebook.com/cloudscaledigital"
      target="_blank"
      className="ccLink"
    >
      Cloudscale Digital
    </a>
  );

  const experienceCard = () => {
    let link = "https://csikimuzeum.ro/en/the-museum/cultural-experience-card";
    if (langCtx.language === "hu") {
      link = "https://csikimuzeum.ro/hu/muzeum/kulturalis-elmenykartya";
    } else if (langCtx.language === "ro") {
      link = "https://csikimuzeum.ro/ro/muzeul/card-de-experienta-culturala";
    }

    return (
      <a
        href={link}
        target="_blank"
        className="ccLink"
        style={{ paddingRight: "2px" }}
      >
        {t("museumName")} website
      </a>
    );
  };

  const formLink = () => {
    let link = "https://csikimuzeum.ro/en/the-museum/cultural-experience-card";
    if (langCtx.language === "hu") {
      link = "https://csikimuzeum.ro/hu/muzeum/kulturalis-elmenykartya";
    } else if (langCtx.language === "ro") {
      link = "https://csikimuzeum.ro/ro/muzeul/card-de-experienta-culturala";
    }

    return (
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSej2Z0hBu3LkEMnXjhP6YYIaThMYNdZnsgeHEEoFvMd5E40XA/viewform"
        target="_blank"
        className="ccLink"
        style={{ paddingRight: "2px" }}
      >
        {t("forInstitutes")}
      </a>
    );
  };
  return (
    <div
      className={`footer ${isContentLong ? "ss" : "sticky-footer"}`}
      style={{ color: config.CAMELCODING.textColor, ...style }}
    >
      <div className="ccHolder">
        <span className="poweredBy" style={{ color: config.MUZEUM.textColor }}>
          {t("footerReadMore")} {experienceCard()}
        </span>
        <span className="poweredBy" style={{ color: config.MUZEUM.textColor }}>
          {t("footerPoweredBy")}: {ccLink}
        </span>

        <span className="poweredBy" style={{ color: config.MUZEUM.textColor }}>
          {t("partners")}:{galaxisLink}
          {cloudScaleLink}
        </span>
        <span className="poweredBy" style={{ color: config.MUZEUM.textColor }}>
          {formLink()}
        </span>
      </div>
    </div>
  );
};

export default Footer;
