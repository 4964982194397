import axios from 'axios';
import {
  balanceOfZoom,
  nameOfContractOfZoom,
  tokenOfOwnerByIndexZoom,
  tokenUrisZoom,
  getCollecitonIdByTokenId,
} from './contractMethdos';

export async function getTokenData(
  walletAddress,
  contractAddress,
  justMetaArray = false
) {
  let fetchedTokenList = null;
  // balanceOfAll;
  const balanceOfAll = await balanceOfZoom(walletAddress, contractAddress);
  // console.log(balanceOfAll, ' balance of all');

  //tokenOfOwnerByIndex
  const tokens = await tokenOfOwnerByIndexZoom(walletAddress, balanceOfAll);
  // console.log(tokens, ' tokens');
  //tokenuri
  const tokenUris = await tokenUrisZoom(tokens);
  if (justMetaArray) fetchedTokenList = await fetchUris(tokenUris);
  return fetchedTokenList || tokenUris;
}

export async function getMetaData(walletAddress, contractAddress) {
  const tokenData = await getTokenData(walletAddress, contractAddress);
  const nameOfContract = await nameOfContractOfZoom(contractAddress);
  //console.log(nameOfContract, "name of contract");

  let metas = {};
  for (let i = 0; i < tokenData.length; i++) {
    for (let z = 0; z < nameOfContract.length; z++) {
      if (tokenData[i].contractAddress === nameOfContract[z].contractAddress) {
        if (metas[nameOfContract[z].contractName] == null) {
          let array = [];
          metas[nameOfContract[z].contractName] = array;
        }
        metas[nameOfContract[z].contractName].push(tokenData[i].tokenUri);
      }
    }
  }

  //console.log(metas, 'metas')
  return metas;
}

export async function collectionIdByTokenId(walletAddress, contractAddress) {
  const tokenData = await getTokenData(walletAddress, contractAddress, false);
  const tokenIds = Object.values(tokenData).map((data) => data.tokenId);

  const mintedCollectionsId = await Promise.allSettled(
    tokenIds.map((tokenId) =>
      getCollecitonIdByTokenId(tokenId).then((collectionId) =>
        collectionId.toNumber()
      )
    )
  );

  return mintedCollectionsId
    .filter((result) => result.status === 'fulfilled')
    .map((result) => result.value);
}

export const fetchUris = async (tokenUri) => {
  console.log(tokenUri, ' tokenUri tokenuri');
  const metadataArray = await axios.all(
    tokenUri.map((object) =>
      axios.get(object.tokenUri).then((response) => {
        if (response.data) {
          console.log(
            object.tokenUri,
            'object tokenUri',
            response,
            ' response'
          );
          response.data.id = object.id?.toString() || undefined;
          response.data.mintedSupply = object.mintedSupply;

          return response.data;
        }
        // Fetching metadata fail, return an object anyway
        else console.log('fail');
        return {
          tokenId: Object.keys(object)[0],
          id: Object.keys(object)[0],
        };
      })
    )
  );

  return metadataArray.sort((a, b) => {
    return Number(a.tokenId) - Number(b.tokenId);
  });
};
