import WalletPage from "../views/WalletPage.jsx";
import NewCardPage from "../views/newCardPage/NewCardPage.jsx";

const routes = [
  {
    path: "/experience/:collectionId",
    component: <NewCardPage />,
    exact: true,
  },

  {
    path: "/",
    component: <WalletPage />,
    exact: true,
  },
];

export default routes;
