import * as ethers from "ethers";
import { Zoom } from "zoom-next";
import abi from "../../contracts/generic-abi.json";
import zoom4 from "../../contracts/Zoom4.json";
import { fetchUris } from ".";

//export const ZOOM3 = "0xcD518b70Cafe0Acea78D3Dc2E4c8af082b6de0F";
const ZOOM3 = "0x5EB2a698585BE438dF35ac667e9A502C983fe2b3";
const contractAddr = "0x5038D37c9766978a7e84Ea377a13250575c0ad0f";
const rpc =
  "https://still-proud-lake.matic-testnet.quiknode.pro/fde86eb3cabe1cd15797fd87a2bec3c1a5d37c3c/";

//console.log(ZOOM3, 'zoom3');
// console.log(rpc, 'rpc')
// console.log(contractAddr, 'contract')
let provider;
export const rpcProvider = () => {
  if (provider === undefined) {
    return (provider = new ethers.providers.JsonRpcProvider(rpc));
  } else return provider;
};

export const getZoomContract = () => {
  const zoomContract = new ethers.Contract(ZOOM3, zoom4.abi, rpcProvider());
  return zoomContract;
};

const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());

export const getCollectionsNumber = async () => {
  //const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());
  const nrOfCollections = await contract.collectionCount().then().catch();
  console.log(nrOfCollections, " nrOfCollectionsnrOfCollections");
  return nrOfCollections;
};
export const addressAlreadyMinted = async (collectionId, address) => {
  //const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());
  const alreadyMinted = await contract
    .alreadyMintedTo(collectionId, address)
    .then()
    .catch();
  return alreadyMinted;
};

export const getCollectionData = async (collectionId) => {
  //const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());
  const collectionData = await contract
    .collections(collectionId)
    .then()
    .catch();
  return collectionData;
};

export const getCollecitonIdByTokenId = async (tokenId) => {
  //const contract = new ethers.Contract(contractAddr, abi.abi, rpcProvider());
  const collectionIdByTokenId = await contract
    .tokenIdToCollectionId(tokenId)
    .then()
    .catch();
  return collectionIdByTokenId;
};

export const getCollections = async () => {
  const numberOfCollections = await getCollectionsNumber();
  //console.log(numberOfCollections.toNumber(), "nr of collections");

  const nrOfCollections = numberOfCollections.toNumber();

  const collections = [];

  // Create an array of promises for fetching collection data
  const promises = Array.from({ length: nrOfCollections }, (_, i) =>
    getCollectionData(i)
  );

  // Wait for all promises to resolve in parallel
  const collectionDataArray = await Promise.all(promises);

  // Process the collection data
  for (let i = 0; i < nrOfCollections; i++) {
    const collectionData = collectionDataArray[i];

    collections.push({
      id: collectionData[0].toNumber(),
      name: collectionData[2],
      tokenUri: collectionData[3],
      mintedSupply: collectionData[1][0].toNumber(),
    });
  }
  const fetchedCollections = await fetchUris(collections);
  fetchedCollections.sort((a, b) => b.id - a.id);
  //console.log(collections, "collections");
  return fetchedCollections;
};

export const balanceOfZoom = async (walletAddress, contractAddress) => {
  const zoomLibrary = new Zoom();
  let calls = [];

  const contract = new ethers.Contract(contractAddress, abi.abi, rpcProvider());
  const call = zoomLibrary.addCall(
    contract,
    ["balanceOf", [walletAddress]],
    "balanceOf(address) returns (uint256)"
  );
  calls.push(call);

  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = {};

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call)[0];
      const tokenCount = decoded.toNumber();
      if (tokenCount === 0) return;
      result[contractAddress] = tokenCount;
    } catch (error) {
      console.log(`Cannot decode response for ${contractAddress}`);
    }
  });
  // console.log(result, ' result balance of zoom');
  return result;
};

export const nameOfContractOfZoom = async (contractAddress) => {
  const zoomLibrary = new Zoom();
  let calls = [];

  const contract = new ethers.Contract(contractAddress, abi.abi, rpcProvider());
  const call = zoomLibrary.addCall(
    contract,
    ["name"],
    "name() returns (string)"
  );
  calls.push(call);

  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call)[0];
      const contractName = decoded.toString();
      result.push({
        contractAddress: contractAddress,
        contractName: contractName,
      });
    } catch (error) {
      console.log(`Cannot get name for ${contractAddress[index]}`);
    }
  });
  return result;
};

export const tokenOfOwnerByIndexZoom = async (
  walletAddress,
  ownedToknIndexes
) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  Object.keys(ownedToknIndexes).forEach((address, index) => {
    const contract = new ethers.Contract(address, abi.abi, rpcProvider());
    for (let i = 0; i < ownedToknIndexes[address]; i++) {
      const call = zoomLibrary.addCall(
        contract,
        ["tokenOfOwnerByIndex", [walletAddress, i]],
        "tokenOfOwnerByIndex(address, uint256) returns (uint256)"
      );
      calls.push({ contractAddress: address, call });
    }
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call.call)[0];
      result.push({
        contractAddress: call.contractAddress,
        tokenId: decoded.toNumber(),
      });
    } catch (error) {
      console.log(`Cannot decode response for ${call.contractAddress}`);
    }
  });
  return result;
};

export const tokenUrisZoom = async (tokens) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  tokens.forEach((token) => {
    const contract = new ethers.Contract(
      token.contractAddress,
      abi.abi,
      rpcProvider()
    );
    const call = zoomLibrary.addCall(
      contract,
      ["tokenURI", [token.tokenId]],
      "tokenURI(uint256) returns (string)"
    );
    calls.push(call);
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call)[0].toString();
      result.push({
        ...tokens[index],
        tokenUri: decoded,
      });
    } catch (error) {
      console.log(`Cannot decode response for ${call.contractAddress}`);
    }
  });
  return result;
};
