import React, { useContext } from "react";
import huIcon from "../assets/images/HU.png";
import roIcon from "../assets/images/RO.png";
import usIcon from "../assets/images/US.png";
import Select from "react-select";
import { LanguageContext } from "../context/languageContext";
import i18next from "i18next";

const LanguagePicker = () => {
  const langCtx = useContext(LanguageContext);

  const options = [
    { value: "hu", img: huIcon },
    { value: "ro", img: roIcon },
    { value: "en", img: usIcon },
  ];

  const handleLangChange = (e) => {
    langCtx.setLanguage(e.value);
    i18next.changeLanguage(e.value);
  };

  return (
    <div>
      <Select
        className="lang-select"
        value={options.filter((option) => option.value === langCtx.language)}
        options={options}
        onChange={handleLangChange}
        formatOptionLabel={(lang) => (
          <div>
            <img src={lang.img}></img>
          </div>
        )}
        isSearchable={false}
      ></Select>
    </div>
  );
};

export default LanguagePicker;
