import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import translationHu from "./assets/locales/muzeum/hu/translation.json";
// import translationEn from "./assets/locales/muzeum/en/translation.json";
// import translationRo from "./assets/locales/muzeum/ro/translation.json";

import translationHuCC from './assets/locales/cc/hu/translation.json';
import translationEnCC from './assets/locales/cc/en/translation.json';
import translationRoCC from './assets/locales/cc/ro/translation.json';

const fallbackLng = ['hu'];
const availableLanguages = ['hu', 'ro', 'en'];

const resources = {
  en: {
    translation: translationEnCC,
  },
  hu: {
    translation: translationHuCC,
  },
  ro: {
    translation: translationRoCC,
  },
};

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
