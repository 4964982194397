import React, { useState, useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import logout from '../assets/images/x-circle.png';
import errorIcon from '../assets/images/error-icon.png';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import leftArrow from '../assets/images/left.png';
import rightArrow from '../assets/images/right.png';
import { LanguageContext } from '../context/languageContext';

const Carousel = ({ handleClick, allCards, selectedIndex }) => {
  const sliderRef = useRef();
  console.log(allCards, ' allcards');
  console.log(selectedIndex, ' selectedIndex');
  const { t } = useTranslation();
  const langCtx = useContext(LanguageContext);

  var settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s<>\"]+)/g;
    return text.replace(urlRegex, function (url) {
      return (
        '<a href="' +
        url +
        '" target="_blank" style="padding: 60px">' +
        url +
        '</a>'
      );
    });
  }

  // console.log(finalCards[id - 1 ], 'final cardds id');

  return (
    <>
      <Slider {...settings} ref={sliderRef} initialSlide={selectedIndex}>
        {allCards.map((metadata, index) => {
          // console.log(metadata.titleRo, 'ro title')
          return (
            <div className="single-card" key={index}>
              <div className="title-button">
                <h1 className="cardpage-title">
                  {langCtx.language === 'hu'
                    ? metadata.titleHu
                    : langCtx.language === 'ro'
                    ? metadata.titleRo
                    : metadata.titleEn}
                </h1>
                <img src={logout} className="close-btn" onClick={handleClick} />
              </div>
              {metadata.owned === false ? (
                <div className="notOwned">
                  <img src={errorIcon}></img>
                  <p className="notOwned-text">{t('warningText')}</p>
                </div>
              ) : (
                <></>
              )}
              <div className="card-description" key={index}>
                <img src={metadata.image} style={{ cursor: 'pointer' }}></img>
                <div className="carousel-btn-holder">
                  <div
                    className="tricky-carousel-button tricky-prev"
                    onClick={() => {
                      // const reversedCards = finalCards.slice(0).reverse();
                      // console.log(reversedCards, 'reversed cards')
                      // sliderRef.current.slickGoTo((reversedCards[id-1]).id);
                      sliderRef.current.slickPrev();
                    }}
                  >
                    <img src={leftArrow} />
                  </div>
                  <div
                    className="tricky-carousel-button tricky-next"
                    onClick={() => {
                      sliderRef.current.slickNext();
                    }}
                  >
                    {' '}
                    <img src={rightArrow} />
                  </div>
                </div>
                <p
                  className="cardpage-desc"
                  dangerouslySetInnerHTML={{
                    __html: urlify(
                      langCtx.language === 'hu'
                        ? metadata.descriptionHu
                        : langCtx.language === 'ro'
                        ? metadata.descriptionRo
                        : metadata.descriptionEn
                    ),
                  }}
                ></p>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default Carousel;
