import { useEffect, useState, useContext } from 'react';
import { ethers } from 'ethers';
import contractAbi from '../contracts/experienceContract-abi.json';
import { CardContext } from '../context/CardContext';

export const useExperienceCards = (userAddress) => {
  const createContractInstance = (addr, abi, providerOrSigner) => {
    return new ethers.Contract(addr, abi, providerOrSigner);
  };
  const expContractAddr = process.env.REACT_APP_EXP_CREST_CONTRACT;
  const expProviderAddr = process.env.REACT_APP_RPC_CARD_PROVIDER;

  const ethersProvider = new ethers.providers.JsonRpcProvider(expProviderAddr);

  const expTokenContract = createContractInstance(
    expContractAddr,
    contractAbi.abi,
    ethersProvider
  );

  const [usersCrest, setUsersCrest] = useState([]);
  const { setInsigneTokenURI } = useContext(CardContext);

  const bytesToString = (str) => {
    if (typeof str == null) return '';
    const bytes = str.slice(2);
    const arr = bytes.match(/.{1,2}/g);
    const intArr = arr.map((str) => parseInt(str, 16));
    return String.fromCharCode(...intArr);
  };
  useEffect(() => {
    (async function () {
      const balance = parseInt(
        await expTokenContract
          .balanceOf(userAddress)
          .catch((e) => console.log(e, ' error'))
      );
      if (balance < 1) return;
      let tokens = [];
      for (let index = 0; index < balance; index++) {
        const token = await expTokenContract
          .tokenOfOwnerByIndex(userAddress, index)
          .catch((e) => console.log(e, ' error'));
        const tokenName = await expTokenContract
          .tokenName()
          .catch((e) => console.log(e, ' error'));
        const tokenUri = await expTokenContract
          .tokenURI(token)
          .catch((e) => console.log(e, ' error'));
        const base64String = tokenUri.split(',')[1];
        const decodedString = atob(base64String);
        const tokenData = JSON.parse(decodedString);
        setInsigneTokenURI({ name: tokenData.name });

        // const tokenDna = await expTokenContract
        //   .tokenDna(token)
        //   .catch((e) => console.log(e, ' error'));
        // const tokenSvgString = bytesToString(
        //   await expTokenContract.buildSvg(tokenDna)
        // );
        console.log(tokenData.image + ' tokenSvgString');
        tokens.push(tokenData.image);
      }
      setUsersCrest(tokens);
    })();
  }, []);
  return { usersCrest };
};
