import { useMemo, useState, useEffect, useRef, useContext } from "react";
import { getCollections } from "../contracts/contractMethdos/contractMethdos";
import {
  collectionIdByTokenId,
  getTokenData,
} from "../contracts/contractMethdos";
import { addAttributeByOwned, addMintedSupplyToOwned } from "../helper/sorter";
import { CardContext } from "../context/CardContext";

export const useNftsLoad = (address) => {
  const memoedAddress = useMemo(() => address, [address]);
  const contract = "0x5038D37c9766978a7e84Ea377a13250575c0ad0f";

  const [collections, setCollections] = useState([]);
  const [error, setError] = useState(null);
  const [ownedCards, setOwnedCards] = useState([]);
  const [mintedCollectionIds, setmintedCollectionIds] = useState([]);
  const [contentLoaded, setcontentLoaded] = useState(false);
  const loadRef = useRef(false);
  const { setBalanceOfOwnedCards } = useContext(CardContext);

  async function getMintedCollectionIds(walletAddress, contractAddress) {
    console.log("getMintedCollectionIds elott");
    const mintedCollectionIds = await collectionIdByTokenId(
      walletAddress,
      contractAddress
    );
    console.log("getMintedCollectionIds utan");
    return mintedCollectionIds;
  }

  const fetchData = async () => {
    const collectionList = await getCollections()
      .then((response) => {
        if (response.length > 0) {
          //setCollections(response);
          return response;
        }
      })
      .catch((e) => {
        console.log(e, "error");
        setError(e);
      });
    console.log(collectionList, " collectionList");
    await getTokenData(address, contract, true)
      .then((response) => {
        //console.log(Object.values(response), "metadata urls");
        const metadataArray = Object.values(response);
        if (metadataArray.length > 0) {
          // console.log(response, ' metadata urlssssssssssssssssssssss');
          const ownedCardWithAttributes = addMintedSupplyToOwned(
            metadataArray,
            collectionList
          );
          setOwnedCards(ownedCardWithAttributes);
          setBalanceOfOwnedCards(ownedCardWithAttributes.length);
          localStorage.setItem("balanceOf", ownedCardWithAttributes.length);
        }
      })
      .catch((e) => {
        console.log(e, "error");
        setError(e);
      });
    console.log(" getTokenData");
    const mintedIdsList = await getMintedCollectionIds(address, contract)
      .then((response) => {
        //console.log(response, "user minted from these collection ids");
        if (response.length > 0) {
          // console.log(response, ' minted collection ids');
          setmintedCollectionIds(response);
          return response;
        }
      })
      .catch((e) => {
        console.log(e, "error");
        setError(e);
      });
    const allItems = addAttributeByOwned(collectionList, mintedIdsList);

    setCollections(allItems.reverse());
    console.log(mintedIdsList, " mintedIdsList");
    setcontentLoaded(true);
  };

  useEffect(() => {
    if (contract && memoedAddress && loadRef.current === false) {
      fetchData();
      loadRef.current = true;
    }
  }, []);

  return {
    collections,
    error,
    ownedCards,
    mintedCollectionIds,
    contentLoaded,
  };
};
