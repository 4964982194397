import { createContext, useState } from 'react';

export const CardContext = createContext({
  collectionList: null,
  balanceOfOwnedCards: null,
  setBalanceOfOwnedCards: () => {},
  insigneTokenURI: null,
  setInsigneTokenURI: () => {},
});

const CardContextProvider = (props) => {
  const [collectionList, setCollectionList] = useState(null);
  const [balanceOfOwnedCards, setBalanceOfOwnedCards] = useState(null);
  const [insigneTokenURI, setInsigneTokenURI] = useState(null);
  return (
    <CardContext.Provider
      value={{
        collectionList: collectionList,
        setCollectionList: setCollectionList,
        balanceOfOwnedCards: balanceOfOwnedCards,
        setBalanceOfOwnedCards: setBalanceOfOwnedCards,
        insigneTokenURI: insigneTokenURI,
        setInsigneTokenURI: setInsigneTokenURI,
      }}
    >
      {props.children}
    </CardContext.Provider>
  );
};

export default CardContextProvider;
