export const addAttributeByOwned = (allList = [], ownedIdList = []) => {
  let tmpArray = [];
  for (let index = 0; index < allList.length; index++) {
    let item;
    if (ownedIdList.includes(Number(allList[index].id))) {
      item = { ...allList[index], owned: true }
      tmpArray.push(item)
    } else {
      item = { ...allList[index], owned: false }
      tmpArray.push(item)
    }
  }
  return tmpArray;
}

export const addMintedSupplyToOwned = (ownedList = [], allList = []) => {
  let tmpArray = [];
  for (let index = 0; index < ownedList.length; index++) {
    let item;
    for (let innerIndex = 0; innerIndex < allList.length; innerIndex++) {
      if (ownedList[index].name === allList[innerIndex].name) {
        item = { ...ownedList[index], mintedSupply: allList[innerIndex].mintedSupply }
        break;
      }
    }
    tmpArray.push(item)
  }
  return tmpArray;
}