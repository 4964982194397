import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import enLogo from "../assets/images/en_color.svg";
import logo from "../assets/images/logo.svg";
import roLogo from "../assets/images/ro_color.svg";
import { LanguageContext } from "../context/languageContext";
import LanguagePicker from "./LanguagePicker";
import ButtonGroup from "./button-group/ButtonGroup";

const Header = ({ crest = null, address = null }) => {
  const navigate = useNavigate();
  const langCtx = useContext(LanguageContext);

  const goToHomepage = () => {
    navigate("/home");
  };

  return (
    <div className="header">
      <div className="logo-div"></div>
      <div className="header-right-side">
        <LanguagePicker />
      </div>
    </div>
  );
};

export default Header;
