import routes from './routes/Routes.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WalletScreen from './views/WalletPage.jsx';
import LanguageContextProvider from './context/languageContext.js';
import CardContextProvider from './context/CardContext.js';
function App() {
  return (
    <LanguageContextProvider>
      <CardContextProvider>
        <div className="App">
          <BrowserRouter basename="/">
            <Routes>
              {routes.map(({ path, component, exact }) => (
                <Route
                  key={path}
                  path={path}
                  element={component}
                  exact={exact}
                />
              ))}
            </Routes>
          </BrowserRouter>
        </div>
      </CardContextProvider>
    </LanguageContextProvider>
  );
}

export default App;
